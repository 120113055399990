body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bold {
  font-weight: bold;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.light-shadow {
  box-shadow: 0px 0px 9px -0px rgb(0 0 0 / 5%);
}

.center {
  text-align: center !important;
}
.dashboard-todays-statistics,
.summery-activity-log,
.dashboard-branches {
  padding: 0px 0px !important;
}

.flex {
  display: flex;
}

.flex-start {
  justify-content: flex-start;
}
.flex1 {
  flex: 1;
}


.font-30 {
  font-size: 30px;
}
.font-15 {
  font-size: 15px;
}
.font-20 {
  font-size: 20px;
}
.center {
  text-align: center !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}
.font-16 {
  font-size: 16px;
}

.__search_input {
  position: absolute;
  margin-top: 12px;
  border: 1px solid #3f51b5;
  padding: 3px 5px;
  z-index: 1;
}

.__search_input__without_pagination {
  margin-top: 12px;
  border: 1px solid #3f51b5;
  padding: 3px 5px;
  z-index: 1;
  margin-bottom: 10px;
}

.responsive {
  width: 100%;
  overflow: auto;
}

.header {
  position: fixed;
  z-index: 2;
  width: 100%;
}
.ant-card-body {
  padding: 5px;
}

.user-list-header,
.user-list-body {
  display: flex;
  justify-content: space-between;
}

.user-list-body > div:first-child,
.user-list-header > div:first-child {
  flex: 1;
}

.user-list-body > div:last-child,
.user-list-header > div:last-child {
  flex: 3;
}

.user-list-header {
  font-weight: bold;
}

.user-list-body {
  padding: 10px 0px;
}

.user-list-body > div,
.user-list-header > div {
  flex: 2;
}
.user-list-body > div:nth-child(5),
.user-list-header > div:nth-child(5) {
  flex: 4;
}

.user-list-body > div > span:first-child {
  display: none;
}

.pagination-users {
  /* border: 1px solid #6d6d6d; */
  margin-top: 20px;
}

.users-spin {
  border: 1px solid #ddd;
  margin-top: 20px;
}
.user-list-search {
  margin-top: 10px;
}

.add-note-icon {
  cursor: pointer;
}

.user-note {
  display: flex;
  flex-direction: column;
}

.user-note-list > ul {
  list-style: none;
  margin-block-start: 0em;
  padding-inline-start: 40px;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .user-list-header {
    display: none;
  }

  .user-list-body > div > span:first-child {
    display: block;
  }

  .user-list-body > div {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
  }

  .user-list-body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #6d6d6d;
    padding: 0px 10px;
    margin: 10px 0px;
  }

  .site-page-header-responsive {
    display: none;
  }
}

/* Report */

.report-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.page__header {
  background-color: #d9d9d9;
  padding: 5px 0px;
}


.__types {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.__type{
  padding: 3px 10px;
  border:1px solid rgb(201, 201, 201);
  border-right: none;
  cursor: pointer;
  margin: 10px 0px;
  flex: 1;
}

.__type:hover{
  border-bottom: 2px solid #555;
}

.__type:last-child {
  border-right:1px solid rgb(201, 201, 201);
}


.__type_active{
  border-bottom: 2px solid #555;
}

.__type {
  position: relative;
}

.__user_count {
  position: absolute;
  top: -25px;
  left: 0px;
  border: 1px solid #ddd;
  width: 100%;
  
}

/*Custom  Modal */

.__modal_wraper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.__modal {
  width: 100%;
  background-color: rgb(226, 221, 221);
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
}

.__modal_header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c7b7b7;
  padding: 10px 0px;
}

.__modal_body {
  padding: 10px 0px;
}

.__modal_close {
  cursor: pointer;
  padding: 5px 10px;
}

.__modal_close:hover {
  background-color: #888;
  transition: 0.5s;
}

.list-item-cat {
  padding: 0px;
}

.category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 2px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.user-type-title {
  text-align: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.user-list-page-title {
  visibility: visible;
}

.user-details-card-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.user-details-card-wrapper > div {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  margin: 5px;
}
.user-details-card-wrapper > div > span:first-child {
  font-size: 18px;
  font-weight: 400;
}
/* // Media query for Android device  */

@media only screen and (max-width: 600px) {
  .user-list-wrapper {
    padding-top: 30px;
  }

  .ant-page-header {
    padding: 0px;
  }

  .user-details-card-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
 
}


.ant-layout-content {
  overflow: hidden !important;
}
/*  */
/* scrollbar css for specific div  */
  
.users-top-actions {
  margin-bottom: 2px;
}


.users-top-actions::-webkit-scrollbar {
  width: 1px;
}

.users-top-actions::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3); 
  border-radius: 1px;
}

.users-top-actions::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5); 
}